/*
 * Add any global CSS properties here
 */

@import '~nprogress/nprogress.css';
@import '~fontsource-roboto/latin-300-normal.css';
@import '~fontsource-roboto/latin-400-normal.css';
@import '~fontsource-roboto/latin-500-normal.css';
@import '~fontsource-roboto/latin-700-normal.css';
@import '~animate.css/animate.min.css';

@import './colors';

a {
  color: $blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
