$blue: #0082cb;
$blue-focus: #339bd5;
$dark-blue: #071f2b;
$purple: #5a2b83;
$dark-purple: #2f1a45;
$red: #ff4237;
$success: #00d398;
$warn: #ffad00;
$alert: #ff0048;
$white: #fff;
$cool-grey: #f3f3f3;
$light-grey: #b5b5b5;
$medium-grey: #999;
$dark-grey: #555;
